<!--
	@name: designForm
	@description：designForm
	@author: ZengWei
	@date: 2021-09-09 15:45
-->
<template>
  <div
    class="design-container"
    style="height: 100%; position: relative; background: #fff"
    v-loading="loading"
  >
    <div class="quality-meter">
      <div class="quality-meter-tabs">
        <el-button type="default" size="small" class="button-class" @click="$router.back()">
          <i class="el-icon-arrow-left"></i> 返回
        </el-button>
        <el-menu
          style="width: 100%; display: flex; justify-content: center"
          class="el-menu-demo"
          :default-active="defaultName"
          mode="horizontal"
          @select="handleClick"
        >
          <el-menu-item v-if="!isStructure" index="form">
            <svg aria-hidden="true" class="iconfont-svg size-24">
              <use xlink:href="#icona-biaodanyinqinghover" />
            </svg>
            自定义表单
          </el-menu-item>
          <el-menu-item v-if="!isStructure" index="flow">
            <svg aria-hidden="true" class="iconfont-svg size-24">
              <use xlink:href="#icona-liuchengyinqinghover" />
            </svg>
            流程配置
          </el-menu-item>
          <el-menu-item v-if="!isStructure" index="excel">
            <svg aria-hidden="true" class="iconfont-svg size-24">
              <use xlink:href="#iconshujubiaogeyinqing" />
            </svg>
            设置Excel模板
          </el-menu-item>
        </el-menu>
      </div>
      <div class="quality-meter-body">
        <div style="height: 100%" v-show="activeName === 'form'">
          <iframe
            ref="designForm"
            v-if="iframeSrc"
            :src="iframeSrc"
            style="width: 100%; height: 100%"
            frameborder="0"
          ></iframe>
        </div>
        <FlowDesign
          v-if="activeName === 'flow'"
          :object-uuid="obuuid"
          :form-uuid="formUuid"
          :need-flow="needFlow"
        ></FlowDesign>
        <custom-excel
          v-if="activeName === 'excel'"
          :tableInfo="tableInfo">
        </custom-excel>
      </div>
    </div>


		<el-dialog
      title="表单设计预览"
      :visible.sync="dialogVisible"
      :fullscreen="true"
      :custom-class="'common-dialog'"
      append-to-body
		>
			<div class="dialog-main">
				<PcParser
          ref="formParser"
					v-if="previewData && dialogVisible"
					:form-data="previewData"
          :displayData="displayData">
				</PcParser>
			</div>
<!--			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="dialogVisible = false">确 定</el-button>
			</div>-->
		</el-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import { formSrc } from "@/libs/util";
import object from "@/api/objects";
import PcParser from "@/custom-component/form/newParser/PcParserNew";
import skeleton from "@/components/formParser/skeleton";
import FlowDesign from "@/components/formParser/flowEngine/index";
import {Menu, MenuItem} from "element-ui";
import customExcel from "./customExcel";

export default {
  name: "designForm",
  components: {
    PcParser,skeleton,FlowDesign,
    'el-menu':Menu,
    'el-menu-item': MenuItem,
    customExcel
  },
  props: {
    path: {
      type: String,
      default: formSrc() + "/#/design",
    },
  },
  data() {
    return {
      newPath: '',
      obuuid: this.$route.query.obuuid,
      formUuid:this.$route.query.uuid,
      name:this.$route.query.name,
      needFlow:this.$route.query.needFlow,
      parent_uuid:this.$route.query.parent_uuid || null,
      loading: true,
      iframeWindow: null,
      formDesignData: null,
      displayData: {},
	    dialogVisible: false,
	    previewData: null,

      defaultName:'form',
      activeName:'form',
      isStructure:false,// 是否是原始库数据
      showList:false,
      iframeSrc: null
    };
  },
  computed:{
    tableInfo: function(){
      return {
        module_uuid: this.formUuid,
        object_uuid: this.obuuid,
      }
    }
  },
  created() {
    window.saveFormDesign = this.saveFormDesign.bind(this);
    window.changeDisplay = this.changeDisplay.bind(this);
	  window.previewPC = this.previewPC.bind(this);
  },
  mounted() {
    if(this.obuuid){
      this.iframeSrc = formSrc() + "/#/design?obuuid=" + this.obuuid;
    } else {
      this.iframeSrc = formSrc() + "/#/design";
    }
    this.initIframeWindow();
  },
  methods: {
    handleClick(data){
      this.activeName = data;
    },
    initIframeWindow() {
      const _this = this;
      this.timer = setInterval(() => {
        let iframe = this.$refs.designForm?.contentWindow;
        if (typeof iframe?.formDesignRecover === "function") {
          clearInterval(_this.timer);
          object.transFields(_this.formUuid && _this.formUuid !='null' ? _this.formUuid : _this.obuuid).then((res) => {
            if (res.data.code == 200) {
              _this.formDesignData = res.data.data;
              iframe.formDesignRecover(JSON.stringify(_this.formDesignData));
            }
            _this.loading = false;
          });
        }
      }, 100);
    },
    saveFormDesign(formData) {
      let data = {
        object_uuid: this.obuuid,
        form_json: JSON.stringify(formData),
      };
      if(this.name && this.name !== 'null'){
        data.name = this.name;
        data.uuid = this.formUuid;
        data.parent_uuid = this.parent_uuid;
      }
      this.$confirm("请确认相关配置配置完成, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          object.saveObjectDesign(data).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                type: "success",
                message: res.data.msg,
              });
            }
          });
        })
        .catch(() => {});
    },
    changeDisplay(mode) {
      if (mode === 1) {
        this.$router.replace({
          name: "objectDetail",
          query: this.$route.query,
        });
      }
    },
	  previewPC(formDesignData){
		  this.previewData = formDesignData
		  this.dialogVisible = true
	  },
  },
  destroyed() {
    clearInterval(this.timer);
  }
};
</script>
<style lang="less" scoped>
.size-24{
  width: 24px;
  height: 24px;
  margin-right: 5px;
}
:deep(.common-dialog) {
  .el-dialog__body{
    padding: 0 10px;
  }
}

.dialog-main{
  padding: 0 15px;
  height: calc(100vh - 60px);
  overflow-y: auto;
}


.design-container{
  .quality-meter {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    .quality-meter-tabs {
      display: flex;
      background-color: #fff;
      justify-content: center;
      position: relative;
      .button-class {
        z-index: 1;
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
      }
    }
    .quality-meter-body {
      flex: 1;
      width: 100%;
      background-color: #fff;
      box-sizing: border-box;
    }
  }
}

</style>
